import { keyframes } from 'styled-components'

export const FadeInAnim = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2px);

  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }
`
