import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../MediaQueries'
import { FullWidthContainer } from 'components/Container'
import Heading from 'components/Heading'
import Paragraph from 'components/Paragraph'
import bg from 'images/bg.png'
import NavbarConditional from 'components/Navbar'

const HeaderContent = styled(FullWidthContainer)`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
  text-align: center;

  > ${Heading} {
    margin-top: 0;
    margin-bottom: 32px;
  }

  > ${Paragraph} {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const HeroHeader = styled(({ className, title, text }) => (
  <div className={className}>
    <NavbarConditional />
    <HeaderContent>
      <Heading size="hero" as="h1">
        {title}
      </Heading>
      <Paragraph>{text}</Paragraph>
    </HeaderContent>
  </div>
))`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 480px;
  background-image: url(${bg});
  background-color: #ccdcdc;
  background-position: center;

  @media (min-width: ${breakpoints.tabletMin}) {
    height: 600px;
    padding-top: 40px;
`

export default HeroHeader
