import React, { useState } from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../MediaQueries'
import { FullWidthContainer } from 'components/Container'
import CenterText from 'components/CenterText'
import TwoColumnDiv from 'components/TwoColumnDiv'
import Footer from 'components/Footer'
import Navbar from 'components/Navbar'
import Promo from 'components/Promo'
import first from 'images/1.jpg'
import second from 'images/2.jpg'
import third from 'images/3.jpg'
import VideoStill from 'images/video-still.jpg'
import playButton from 'images/playbutton.svg'

const Layout = styled(({ children, className }) => (
  <div className={className}>
    <Header />
    {children}
    <Footer />
  </div>
))`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const Spacer = styled.div`
  @media (min-width: ${breakpoints.tabletMin}) {
    width: 100%;
    height: 130px;
  }
`

const FullWidthContainerNoMargin = styled(FullWidthContainer)`
  padding: 0;
  @media (min-width: ${breakpoints.tabletMin}) {
    padding: 0 0 130px 0;
  }
`

const Header = styled(({ className }) => (
  <div className={className}>
    <Navbar />
  </div>
))`
  @media (min-width: ${breakpoints.tabletMin}) {
    padding-top: 40px;
  }
`

const CenterVideoCont = styled.div`
  width: 80vw;
  min-width: 20em;
  height: 400px;
  margin: 0 auto;
  margin-bottom: 190px;

  background-color: gray;
  background-size: cover;
  background-position: center;
  display: block;
  margin-bottom: 117px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-image: url(${VideoStill});
  object-fit: contain;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.tabletMin}) {
    width: 950px;
    height: 578px;
  }
`

const PlayButton = styled.a`
  display: block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  background-image: url(${playButton});

  @media (min-width: ${breakpoints.tabletMin}) {
    width: 140px;
    height: 140px;
  }
  `



const About = () => {
  const [playVideo, setPlayVideo] = useState(false)
  
  return (
    <Layout>
      <Spacer />
      <CenterText
        centerTitle="We are on a mission to turn coffee lovers into home roasters"
        centerText="Coffee roasting is simple. You don't require expensive equipment or need the knowledge to do so. We provide you all the tools you need to get started and discover coffee in a new way. Benjamin is not a specific person, but a figure from our history that represents everyone. You can create speciality coffee at home."
      ></CenterText>
      <CenterVideoCont>
        {playVideo && <iframe title="video" style={{margin: '0 auto'}} width="100%" height="100%" src="https://www.youtube.com/embed/kAvr2CCYYLA?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
        {!playVideo && <PlayButton href="#" onClick={(e) => {
          e.preventDefault()
          setPlayVideo(true)
          }} target="_blank" rel="noopener" />}
      </CenterVideoCont>
      <FullWidthContainerNoMargin>
        <TwoColumnDiv
          leftTitle="Everything you need to become a homeroaster"
          leftText="Coffee by Benjamin is your home roasting coffee experience. Want to know everything about roasting green coffee in your own oven? Our Starter Kit contains everything to turn you into a home roaster; a custom metal tray, Bluetooth thermometer, the Coffee by Benjamin app and three high quality coffees."
          image={first}
        ></TwoColumnDiv>
      </FullWidthContainerNoMargin>
      <FullWidthContainerNoMargin>
        <TwoColumnDiv
          flexDirection="row-reverse"
          leftTitle="The best beans for the freshest coffee"
          leftText="We only select raw coffee beans of the highest quality. All our coffees are specifically selected because of their home roasting capabilities. The Starter Kit contains three premium (AA) single-origin coffees, with various flavors and enough for a month of your own home roasted coffee."
          image={second}
        ></TwoColumnDiv>
      </FullWidthContainerNoMargin>
      <FullWidthContainerNoMargin>
        <TwoColumnDiv
          leftTitle="The Starter Kit is only the beginning of your journey"
          leftText="All out of coffee? Just reorder your favorite beans from our webshop! We plan to release new coffees from other regions on our website and app. If Coffee by Benjamin makes you excited about roasting, wait until you have experienced your own coffee roasts with beans from all over the world. This is only the beginning of your journey."
          image={third}
        ></TwoColumnDiv>
      </FullWidthContainerNoMargin>
      <Promo />
    </Layout>
  )
}

export default About
