import styled, { keyframes } from 'styled-components/macro'
var React = require('react')

const SlideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2px);
  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }

  100%{
    opacity: 0;
    transform: translateY(2px);
  }
`

const ContainCheckmark = styled.div`
  display: inline-block;
  animation: ${SlideUp} 1.6s ease-in;
`
const Line = styled.polyline`
  stroke-dasharray: 100px, 100px;
  stroke-dashoffset: 200px;
  transform: scale(0.3) translateY(8px);
  animation: checkmark 1.6s ease-in;
  opacity: 0;

  @keyframes checkmark {
    0% {
      stroke-dashoffset: 100px;
      opacity: 1;
    }

    30% {
      stroke-dashoffset: 0px;
      opacity: 1;
    }

    60% {
      stroke-dashoffset: 0px;
      opacity: 1;
    }

    100% {
      opacity: 0;
      stroke-dashoffset: 100px;
    }
  }
`

function Checkmark() {
  return (
    <ContainCheckmark>
      <svg xmlns="http://www.w3.org/2000/svg" width="31px" height="22px">
        <g fill="none" stroke="#22AE73" stroke-width="">
          <Line className="checkmark" stroke="#000" strokeWidth="6" points="10.5,44.8 29.7,64.9 76.2,16.4 " />
        </g>
      </svg>
    </ContainCheckmark>
  )
}
export default Checkmark
