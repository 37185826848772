import styled from 'styled-components/macro'
import Button from 'components/Button'
import Heading from 'components/Heading'
import Paragraph from 'components/Paragraph'
import { FadeInAnim, FadeInOutAnim } from 'styles/Keyframes/index.js'

const ShopPopup = styled.div`
  display: flex;
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100%;
  padding: 20px;
  background-color: white;
  transition: all 0.2s;
  transform: translateY(10px);
  opacity: 0;
`

export const ShopButton = styled(Button)`
  align-self: center;
  display: inline-block;

  p {
    margin: 0;
    padding: 0;
    line-height: 35px;
    display: inline-block;
  }

  &:first-child {
    background-color: white;
    color: black;
  }

  &:last-child {
    background-color: #181818;
    color: white;
    &:hover {
      background-color: #181818;
    }
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;

  a > img {
    width: 100%;
    border-radius: 4px;
  }
`

export const ProductInfo = styled.div``

export const ProductTitle = styled(Heading)`
  font-size: 24px;
  line-height: 24px;
  font-weight: medium;
  color: black;
`

export const ProductPrice = styled(Paragraph)`
  color: rgba(0, 0, 0, 0.45);
  margin: 30px 0;
`

export const FadeInOut = styled.p`
  animation-name: ${FadeInOutAnim};
  animation-timing-function: ease-in;
  animation-duration: ${props => (props.animationEnabled ? '1.6s' : '0')};
`

export const FadeIn = styled.p`
  animation-name: ${FadeInAnim};
  animation-timing-function: ease-in;
  animation-duration: ${props => (props.animationEnabled ? '1.6s' : '0')};
`

export default ShopPopup
