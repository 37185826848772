import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../MediaQueries'
import QR from 'images/qr.png'
import Button from 'components/Button'

const QrCode = styled.img.attrs({ src: QR })`
  width: 42px;
  @media (min-width: ${breakpoints.tabletMin}) {
    width: 72px;
  }
`

const Container = styled.div`
  margin-top: 20px;

  @media (min-width: ${breakpoints.tabletMin}) {
    margin-top: 80px;
  }
  
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CtaButton = styled(Button)`
  width: 240px;
  font-size: 15px;
  margin-right: 20px;

  &:hover {
    background-color: #181818;
  }
  @media (min-width: ${breakpoints.mobileMin}) {
    font-size: 18px;
    width: unset;
  }
`

const Text = styled.div`
  padding-left: 8px;
  font-weight: bold;
`

const Link = styled.a`
  color: black;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`


function useMediaQuery(mq) {
  const mql = window.matchMedia(mq)
  const [matches, setMatches] = React.useState(mql.matches)

  React.useEffect(() => {
    const listener = e => {
      setMatches(e.matches)
    }
    mql.addListener(listener)
    return () => {
      mql.removeListener(listener)
    }
  }, [mq, mql])

  return matches
}

function AppDownload(props) {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobileMax})`)

  return (
    isMobile ?
        <Container>
          <Link href='/download' title='Download app'>
          <CtaButton>
            <p>Download app</p>
          </CtaButton>
          </Link>
        </Container>
      :
        <Container>
          <QrCode />
          <Text>Scan to download the app (iOS, Android)</Text>
        </Container>
  )
}



export default AppDownload
