import React from 'react'
import styled, { css } from 'styled-components/macro'
import { breakpoints } from '../MediaQueries'
import arrow from '../images/arrow.png'

const Title = styled.h3`
  user-select: none;
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-right: 16px;

  @media (min-width: ${breakpoints.tabletMin}) {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
  }
`

const Content = styled.div`
  max-height: 0;
  transition: max-height 0.4s ease-out;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  p {
    margin: 16px;

    @media (min-width: ${breakpoints.tabletMin}) {
      margin: 32px;
    }
  }

  ${props =>
    props.isOpen &&
    css`
      max-height: 500px;
      transition: max-height 0.5s ease-in;
    `};

  @media (min-width: ${breakpoints.tabletMin}) {
    font-size: 20px;
    line-height: 32px;

    p {
      margin-top: 32px;
    }
  }
`

const Ruler = styled.hr`
  margin: 16px;
  margin-top: 0;
  border: 1.5px solid #f3f3f3;

  @media (min-width: ${breakpoints.tabletMin}) {
    margin: 32px;
    margin-top: 0;
  }
`

const FaqSectionContainer = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  overflow: hidden;

  & + & {
    margin-top: 32px;
  }
`

const Arrow = styled.img.attrs({ src: arrow })`
  width: 16px;
  transition: transform 0.5s;
  flex: 1 0 auto;

  ${props => props.isOpen && `transform: rotate(180deg);`};

  @media (min-width: ${breakpoints.tabletMin}) {
    width: 22px;
  }
`

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;

  @media (min-width: ${breakpoints.tabletMin}) {
    padding: 32px;
  }
`

const FaqSection = ({ title, content }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggle = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  return (
    <FaqSectionContainer isOpen={isOpen}>
      <TitleContainer onClick={toggle}>
        <Title>{title}</Title>
        <div>
          <Arrow isOpen={isOpen} />
        </div>
      </TitleContainer>
      <Content isOpen={isOpen}>
        <Ruler />
        <p>{content}</p>
      </Content>
    </FaqSectionContainer>
  )
}

export default FaqSection
