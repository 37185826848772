import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../MediaQueries'
import Footer from 'components/Footer'
import { Link } from 'react-router-dom'
import HeroHeader from 'components/HeroHeader'
import Paragraph from 'components/Paragraph'
import { Container } from 'components/Container'
import FaqSection from 'components/FaqSection'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  span > a {
    color: black;
    text-decoration: underline;
  }
`

const NarrowContainer = styled(Container)`
  max-width: 960px;
`

const HelpText = styled(Paragraph)`
  margin: 40px auto;
  display: block;
  width: 90vw;
  text-align: center;
  line-height: 32px;
  margin-bottom: 40px;

  @media (min-width: ${breakpoints.tabletMin}) {
    margin-top: 100px;
    margin-bottom: 160px;
    width: 420px;
  }

  > a {
    color: black;
    text-decoration: underline;
  }
`

const FaqContainer = styled(NarrowContainer)`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  > :first-child {
    margin-top: -30px;
  }

  @media (min-width: ${breakpoints.tabletMin}) {
    > :first-child {
      margin-top: -50px;
    }
  }
`

function Faq() {
  return (
    <Layout>
      <HeroHeader
        title="Frequently Asked Questions"
        text={
          <span>
            If you can’t find your answer, please check out our <Link to="/support">support page &rarr;</Link>
          </span>
        }
      />
      <FaqContainer>
        <FaqSection
          title="Can I connect the thermometer to any device?"
          content="Both Android and Apple iPhone are supported. However, it does not work with older devices as it will require Bluetooth 4.0, also known as Bluetooth Low Energy  (older than iPhone 4s and some Android devices)."
        />
        <FaqSection
          title="My thermometer does not connect, what can I do?"
          content="If your device is supported and it seems to have trouble connecting, please reach out to support"
        />
        <FaqSection
          title="I want more coffee!?!"
          content="We plan to introduce other green coffees from different regions and take you on a journey around the world."
        />
        <FaqSection
          title="Does it work with every oven?"
          content="We tested it with lots of ovens and the quality of the end result really depends on multiple factors. Smaller (combination) ovens tend to problems with the airflow. It does work, but you might be required to shuffle the beans a bit during roasting. Only ovens with hot air will work, as the beans require proper airflow."
        />
        <FaqSection
          title="Can I roast beans in a gas, non-ventilated oven?"
          content="Gas non-ventilated ovens will be tricky. We're talking about heat from open flame here, which will probably burn the beans. Also, without ventilation, there'll be slightly more smoke."
        />
        <FaqSection
          title="Can I also buy green beans elsewhere?"
          content="Probably, but not all green beans are suitable for home roasting. We've selected the best quality coffees for home roasting and added specific instructions per bean type. We would recommend using our coffee beans obviously, not just because we like you as a customer - but we can guarantee the quality of the green coffee beans."
        />
        <FaqSection
          title="Can I blend these coffees?"
          content="We don't recommend it as these coffees are so unique in flavor, that it doesn't taste well and it would be a waste of the coffee. Blends are never created with coffees that are from the same geographical region. We are considering to add this ability later on with other coffees."
        />
        <FaqSection
          title="What's the warranty?"
          content="We provide a 24-month warranty on orders, same as retail."
        />
        <FaqSection
          title="How long does one box of coffee last?"
          content="It really depends on how much coffee you drink and how much people you drink it with. An average household can last up to 3 to 4 weeks per box of coffee."
        />
        <HelpText>
          If you can’t find your answer, please check out our <Link to="/support">support page &rarr;</Link>
        </HelpText>
      </FaqContainer>

      <Footer displayRuler="block" />
    </Layout>
  )
}

export default Faq
