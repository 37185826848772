import React, { useContext } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { Container } from 'components/Container'
import { breakpoints } from 'MediaQueries'
import { CartContext } from 'modules/shop/CartContext'
import { ReactComponent as HamburgerIcon } from 'images/hamburger.svg'
import { ReactComponent as CloseIcon } from 'images/close.svg'
import { ReactComponent as ShopIcon } from 'images/shop.svg'
import Logo from 'components/Logo'

const HEADER_HEIGHT = '60px'

const OpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
`

const Filler = styled.div`
  height: ${HEADER_HEIGHT};
`

const NavContainer = styled(Container)`
  width: 100%;
  max-width: unset;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: transparent;
  height: ${HEADER_HEIGHT};
  z-index: 999;

  > a {
    height: 16px;
  }

  ${props =>
    props.scrolled &&
    css`
      animation: ${OpacityAnimation} 0.5s;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
      background-color: white;
      position: fixed;
      top: 0px;
    `};

  ${props =>
    props.theme.darkMode &&
    css`
      background-color: black;
    `}
`

const NavLinks = styled.div`
  display: none;

  @media (min-width: ${breakpoints.tabletMin}) {
    display: block;
    vertical-align: middle;
  }
`

const NavLink = styled(Link)`
  color: ${props => props.theme.header.link};
  font-weight: 500;
  margin-right: 16px;
  transition: color 0.3s;
  line-height: 22px;

  &:hover {
    color: ${props => props.theme.header.linkHover};
  }

  &:last-child {
    margin-right: 0;
  }
`

const MobileMenu = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 999;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 500;

  ${props => props.active && `display: block`};

  @media (min-width: ${breakpoints.tabletMin}) {
    display: none;
  }
`

const MobileMenuContent = styled.div`
  margin-top: 50px;
`

const PrimaryMobileLinks = styled.div`
  margin-bottom: 32px;
`

const SecondaryMobileLinks = styled.div``

const PrimaryMobileNavLink = styled(Link)`
  display: block;
  font-size: 20px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  margin-bottom: 16px;
`

const SecondaryMobileNavLink = styled(PrimaryMobileNavLink)`
  color: rgba(0, 0, 0, 0.45);
`

const Hamburger = styled(({ className, onClick, active }) => {
  const Icon = active ? CloseIcon : HamburgerIcon
  return <Icon className={className} onClick={onClick} />
})`
  cursor: pointer;
  z-index: 999;
  fill: #131313;

  ${props =>
    props.theme.darkMode &&
    css`
      fill: #fff;
    `}

  @media (min-width: ${breakpoints.tabletMin}) {
    display: none;
  }
`
const CartIconContainer = styled.div`
  display: inline-block;
  position: relative;
`
const CartCount = styled.div`
  position: absolute;
  top: -5px;
  right: -9px;
  width: 15px;
  box-sizing: content-box;
  height: 15px;
  text-align: center;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  font-size: 10px;
  line-height: 15px;
  background-color: red;
`

const CartIcon = styled(ShopIcon)`
  display: none;
  fill: #131313;
  transition: opacity .2s;

  :hover{
    opacity: .6;
  }

  ${props =>
    props.theme.darkMode &&
    css`
      fill: #fff;
    `}

  @media (min-width: ${breakpoints.tabletMin}) {
    display: inline-block;
    margin-left: 10px;
    transform: translate(0, 2px);
  }
`

const MobileCartIcon = styled(ShopIcon)`
  fill: #131313;

  ${props =>
    props.theme.darkMode &&
    css`
      fill: #fff;
    `}
`

function useMediaQuery(mq) {
  const mql = window.matchMedia(mq)
  const [matches, setMatches] = React.useState(mql.matches)

  React.useEffect(() => {
    const listener = e => {
      setMatches(e.matches)
    }
    mql.addListener(listener)
    return () => {
      mql.removeListener(listener)
    }
  }, [mq, mql])

  return matches
}

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false)
  const toggleMobileMenu = React.useCallback(() => setIsMobileMenuOpen(!isMobileMenuOpen), [
    isMobileMenuOpen,
    setIsMobileMenuOpen,
  ])
  const [isScrolled, setIsScrolled] = React.useState(false)
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobileMax})`)

  React.useEffect(() => {
    const scrollListener = () => {
      const isTop = window.scrollY <= 40
      if (!isTop) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', scrollListener)

    return () => window.removeEventListener('scroll', scrollListener)
  }, [])

  React.useEffect(() => {
    // scroll lock
    document.body.style = isMobile && isMobileMenuOpen ? `overflow: hidden;` : ``
    return () => {
      document.body.style = ``
    }
  }, [isMobile, isMobileMenuOpen])

  const { getTotalCartItems } = useContext(CartContext)

  return (
    <div>
      {isScrolled && <Filler />}
      <NavContainer scrolled={isScrolled}>
        <Hamburger active={isMobileMenuOpen} onClick={toggleMobileMenu} />
        <MobileMenu active={isMobileMenuOpen}>
          <MobileMenuContent>
            <PrimaryMobileLinks>
              <PrimaryMobileNavLink to="/about">About</PrimaryMobileNavLink>
              <PrimaryMobileNavLink to="/shop">Shop</PrimaryMobileNavLink>
            </PrimaryMobileLinks>
            <SecondaryMobileLinks>
              <SecondaryMobileNavLink to="/support">Support</SecondaryMobileNavLink>
              <SecondaryMobileNavLink to="/faq">FAQ</SecondaryMobileNavLink>
              <SecondaryMobileNavLink to="/privacy">Privacy Policy</SecondaryMobileNavLink>
              <SecondaryMobileNavLink to="/press-kit">Press Kit</SecondaryMobileNavLink>
            </SecondaryMobileLinks>
          </MobileMenuContent>
        </MobileMenu>
        <Link to="/">
          <Logo />
        </Link>
        {isMobile && (
          <Link to="/cart">
            <CartIconContainer>
              <MobileCartIcon />
              {getTotalCartItems() > 0 && <CartCount>{getTotalCartItems()}</CartCount>}
            </CartIconContainer>
          </Link>
        )}
        <NavLinks>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/shop">Shop</NavLink>
          <Link to="/cart">
            <CartIconContainer>
              <CartIcon />
              {getTotalCartItems() > 0 && <CartCount>{getTotalCartItems()}</CartCount>}
            </CartIconContainer>
          </Link>
        </NavLinks>
      </NavContainer>
    </div>
  )
}

export default Navbar
