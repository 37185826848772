import './styles/normalize.css'
import './styles/main.css'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import Home from './pages/home'
import About from './pages/about'
import Faq from './pages/faq'
import PressKit from './pages/press-kit'
import NotFound from './pages/not-found'
import Support from './pages/support'
import Privacy from './pages/privacy'
import Download from './pages/download'
import { lightTheme } from 'theme'
import * as serviceWorker from './serviceWorker'
import ScrollToTop from 'components/ScrollToTop'

function lazyWithPreload(factory) {
  const Component = React.lazy(factory)
  Component.preload = factory
  return Component
}

// Lazy load shop routes
const ShopifyProvider = lazyWithPreload(() => import('modules/shop/ShopifyContext'))
const CartProvider = lazyWithPreload(() => import('modules/shop/CartContext'))
const Shop = lazyWithPreload(() => import('./pages/shop'))
const Cart = lazyWithPreload(() => import('./pages/cart'))
const ProductDetail = lazyWithPreload(() => import('./pages/product-detail'))

ShopifyProvider.preload()
CartProvider.preload()
Shop.preload()
Cart.preload()
ProductDetail.preload()

const routing = (
  <ThemeProvider theme={lightTheme}>
    <Suspense fallback={<div>Loading...</div>}>
      <ShopifyProvider>
        <CartProvider>
          <Router>
            <ScrollToTop>
              <div>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/faq" component={Faq} />
                  <Route path="/press-kit" component={PressKit} />
                  <Route path="/support" component={Support} />
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/shop" component={Shop} />
                  <Route path="/cart" component={Cart} />
                  <Route path="/about" component={About} />
                  <Route path="/product/:productId/:couponId" component={ProductDetail} />
                  <Route path="/product/:productId" component={ProductDetail} />
                  <Route path="/download" component={Download} />
                  <Route path="*" component={NotFound} />
                </Switch>
              </div>
            </ScrollToTop>
          </Router>
        </CartProvider>
      </ShopifyProvider>
    </Suspense>
  </ThemeProvider>
)
ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
