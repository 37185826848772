import React from 'react'
import styled from 'styled-components'
import Heading from 'components/Heading'
import Paragraph from 'components/Paragraph'
import { FullWidthContainer } from 'components/Container'
import { breakpoints } from '../../MediaQueries'
import MailChimp from '../MailChimp'

const PromoSection = styled.div`
  text-align: center;
  background-color: #d6e4e4;
  padding: 0 10px;
`

const PromoContainer = styled(FullWidthContainer)`
  > ${Heading} {
    margin-top: 0;
  }

  width: 90%;

  @media (min-width: ${breakpoints.tabletMin}) {
    padding-top: 160px;
    padding-bottom: 160px;
    max-width: 990px;
  }
`

function Promo() {
  return (
    <PromoSection>
      <PromoContainer>
        <Heading size="hero">Subscribe to our newsletter for news, tips and special deals</Heading>
        <Paragraph style={{ marginTop: 40, marginBottom: 40, color:'rgba(0, 0, 0, 0.85)' }}>
          Let us keep you informed about everything Coffee by Benjamin and be one of the first to know about news, tips and special deals.
        </Paragraph>{' '}
        <MailChimp />
      </PromoContainer>
    </PromoSection>
  )
}

export default Promo
