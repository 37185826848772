import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../MediaQueries'
import { Container } from 'components/Container'
import Footer from 'components/Footer'
import Spinner from 'components/Spinner'
import { ReactComponent as Checkmark } from 'images/icons/checkmark.svg'
import SelectArrow from '../../images/selectArrow.svg'
import Button from 'components/Button'
import DarkMode from 'components/DarkMode'
import NavbarConditional from 'components/Navbar'

const DEFAULT = Symbol()

function switch_(states, state) {
  if (!Object.keys(states).includes(state.toString())) return states[DEFAULT]
  return states[state]
}

const Layout = styled(({ children, className }) => (
  <div className={className}>
    <Header />
    {children}

    <Footer displayRuler />
  </div>
))`
  display: flex;
  flex-direction: column;
  background: black;
  min-height: 100vh;
`

const Header = styled(({ className }) => (
  <div className={className}>
    <NavbarConditional />
  </div>
))`
  @media (min-width: ${breakpoints.tabletMin}) {
    padding-top: 40px;
  }
`

const ContentContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 auto;
  margin-top: 60px;
  color: #fff;

  @media (min-width: ${breakpoints.tabletMin}) {
    margin-top: 0;
    align-items: center;
  }
`

const Left = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.tabletMin}) {
    width: 45%;
    margin-right: 20%;
  }
`

const Right = styled.div`
  width: 100%;
  margin-top: 32px;

  @media (min-width: ${breakpoints.tabletMin}) {
    width: 35%;
    margin-top: 0;
  }
`

const Title = styled.h1`
  margin-top: 0;
  font-size: 40px;
  line-height: 46px;
  font-weight: 800;

  @media (min-width: ${breakpoints.tabletMin}) {
    font-size: 48px;
    line-height: 68px;
  }
`

const Text = styled.p`
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
`

const Form = styled.form``

const Label = styled.label`
  display: block;
  width: auto;
  margin-top: 0px;
  width: 100%;
`

const Input = styled.input`
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  background-color: #262626;
  border: none;
  color: white;
  border-radius: 4px;
  padding-left: 18px;
`

const TextArea = styled.textarea`
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  padding-left: 18px;
  font-size: 18px;
  height: 200px;
  line-height: 18px;
  background-color: #262626;
  border: none;
  color: white;
  border-radius: 4px;
  resize: none;
`

const Select = styled.select`
  width: 100%;
  margin-bottom: 20px;
  color: white;
  background-color: #262626;
  border: none;
  padding: 10px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 4px;
  appearance: none;
  background-image: url(${SelectArrow});
  background-position: center right;
  background-repeat: no-repeat;

  option:disabled {
    color: rgba(186, 186, 186, 0.4);
  }
`

const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: ${breakpoints.tabletMin}) {
    display: flex;
  }
`

const actions = {
  RESET_FORM: 'RESET_FORM',
  UPDATE_FIELD_VALUE: 'UPDATE_FIELD_VALUE',
  UPDATE_FORM_STATE: 'UPDATE_FORM_STATE',
}

const resetForm = () => ({ type: actions.RESET_FORM })
const updateFieldValue = event => ({
  type: actions.UPDATE_FIELD_VALUE,
  name: event.target.name,
  value: event.target.value,
})

const formStates = {
  PRE_SUBMIT: 0,
  SUBMITTING: 1,
  SUBMIT_SUCCESS: 2,
  SUBMIT_ERROR: 3,
}

const MIN_SUBMITTING_MS = 1000
const RESET_FORM_STATE_AFTER_SUBMIT_MS = 8000

function ContactForm() {
  const [formState, setFormState] = React.useState(formStates.PRE_SUBMIT)
  const getInitialState = React.useCallback(() => ({ name: '', email: '', subject: '', message: '' }), [])
  const [form, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case actions.UPDATE_FIELD_VALUE:
        return { ...state, [action.name]: action.value }
      case actions.RESET_FORM:
        return getInitialState()
      default:
        return state
    }
  }, getInitialState())

  React.useEffect(() => {
    const timeout =
      formState >= formStates.SUBMIT_SUCCESS
        ? setTimeout(() => setFormState(formStates.PRE_SUBMIT), RESET_FORM_STATE_AFTER_SUBMIT_MS)
        : null

    return () => {
      clearTimeout(timeout)
    }
  }, [formState])

  const dispatchUpdateFieldValue = React.useCallback(e => dispatch(updateFieldValue(e)), [dispatch])
  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()
      setFormState(formStates.SUBMITTING)
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encodeFormData({
          'form-name': 'contact',
          ...form,
        }),
      })
        .then(
          response =>
            new Promise(resolve => {
              setTimeout(() => resolve(response), MIN_SUBMITTING_MS)
            }),
        )
        .then(response => {
          if (response.status > 200) {
            setFormState(formStates.SUBMIT_ERROR)
          } else {
            setFormState(formStates.SUBMIT_SUCCESS)
            dispatch(resetForm())
          }
        })
    },
    [form, dispatch],
  )

  return (
    <Form onSubmit={handleSubmit}>
      {/* Make sure to keep the form fields in sync with the hidden form in `index.html`, for more info see https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/ */}
      <input type="hidden" name="form-name" value="contact" />
      <Label>
        <Input
          type="text"
          name="name"
          placeholder="Name"
          value={form.name}
          onChange={dispatchUpdateFieldValue}
          disabled={formState === formStates.SUBMITTING}
        />
      </Label>
      <Label>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={form.email}
          onChange={dispatchUpdateFieldValue}
          disabled={formState === formStates.SUBMITTING}
        />
      </Label>
      <Select name="subject" value={form.subject} onChange={dispatchUpdateFieldValue}>
        <option value="" disabled selected>
          Select a subject...
        </option>
        <option value="Application">Application</option>
        <option value="Billing/Order">Billing/Order</option>
        <option value="Coffee">Coffee</option>
        <option value="Thermometer">Thermometer</option>
        <option value="Other">Other</option>
      </Select>
      <Label>
        <TextArea
          type="text"
          name="message"
          placeholder="Message"
          value={form.message}
          onChange={dispatchUpdateFieldValue}
          disabled={formState === formStates.SUBMITTING}
        />
      </Label>
      <Button style={{ backgroundColor: 'white' }} type="submit" disabled={formState >= formStates.SUBMITTING}>
        {switch_(
          {
            [formStates.SUBMITTING]: <Spinner />,
            [formStates.SUBMIT_SUCCESS]: (
              <span>
                <Checkmark /> Message sent successfully
              </span>
            ),
            [formStates.SUBMIT_ERROR]: 'An error occurred, please try again later',
            [DEFAULT]: 'Submit',
          },
          formState,
        )}
      </Button>
    </Form>
  )
}

export default function Support() {
  return (
    <DarkMode>
      <Layout>
        <ContentContainer>
          <FormContainer>
            <Left>
              <div>
                <Title>Let us know if you need any help</Title>
                <Text>
                  We are here to help you achieve the best roasting experience. Send us a message if you have any
                  questions.
                </Text>
              </div>
            </Left>

            <Right>
              <ContactForm />
            </Right>
          </FormContainer>
        </ContentContainer>
      </Layout>
    </DarkMode>
  )
}

function encodeFormData(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
