import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { breakpoints } from 'MediaQueries'
import { useAddToCartState } from 'hooks'
import ShopButton from 'components/ShopButton'
import ShopPopup, {
  ImageContainer,
  ProductInfo,
  ProductPrice,
  ProductTitle,
} from 'components/ShopPopupStyling'

const ProductDiv = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  display: none;

  a {
    color: black;
  }

  &:hover ${ShopPopup} {
    display: flex;
    pointer-events: auto;
  }

  &:nth-child(-n + 3) {
    display: block;
  }

  @media (min-width: ${breakpoints.tabletMax}) {
    width: 30%;

    &:hover ${ShopPopup} {
      opacity: 1;
      transform: translateY(0);
      display: flex;
    }
  }
`

function ProductHome({ product }) {
  let variantImage = product.images[0]
  let variant = product.variants[0]

  const { buttonState, onClickAddToCart, onClickBuyNow } = useAddToCartState({
    variantId: variant.id,
    variantQuantity: 1,
  })
  return (
    <ProductDiv>
      <ImageContainer>
        <Link to={`/product/${product.id}`}>
          {variantImage && <img src={variantImage.src} alt={`${product.title} product shot`} />}
        </Link>
        <ShopPopup>
          <ShopButton onClick={onClickAddToCart} disabled={buttonState} buttonState={buttonState} addToCart={true} />
          <ShopButton as={Link} to="/cart" onClick={onClickBuyNow} />
        </ShopPopup>
      </ImageContainer>
      <ProductInfo>
        <Link to={`/product/${product.id}`}>
          <ProductTitle>{product.title}</ProductTitle>
        </Link>
        <ProductPrice>&euro;{variant.price}</ProductPrice>
      </ProductInfo>
    </ProductDiv>
  )
}

export default ProductHome
