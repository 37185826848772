import React from 'react'
import Client from 'shopify-buy'

export const ShopifyContext = React.createContext()

export default function ShopifyProvider({ children }) {
  const client = React.useMemo(
    () =>
      Client.buildClient({
        storefrontAccessToken: '5da020c71d8b9b9b0b21523ddedc57d6',
        domain: 'coffeebybenjamin.myshopify.com',
      }),
    [],
  )

  return <ShopifyContext.Provider value={client}>{children}</ShopifyContext.Provider>
}
