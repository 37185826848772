import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const LiItem = styled.li`
  display: inline-block;
  list-style: none;
  margin: 20px;
  font-size: 20px;
  line-height: 32px;
  width: 25%;
  padding-bottom: 10px;
  border-bottom: 3px solid #efefef;
  position: relative;
  font-weight: 600;
  color: ${props => (props.isActive ? 'rgba(0, 0, 0, 0.85)' : 'rgba(0, 0, 0, 0.45)')};

  &:hover {
    cursor: pointer;
  }

  &:after {
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: #8ac7c4;
    content: '';
    position: absolute;
    bottom: -3px;
    display: block;
  }

  &.tab-active:after {
    animation: ${props => (props.isAnimated ? 'ingredient 5s forwards linear' : 'none')};
  }

  &.tab-active ~ &:after {
    width: 0;
  }

  @keyframes ingredient {
    0% {
      width: 0;
    }

    100% {
      width: 100%;
    }
  }
`

class IngredientTabs extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    const { label, onClick } = this.props
    onClick(label)
  }

  render() {
    const {
      onClick,
      props: { label, isActive, isAnimated },
    } = this

    let className = isActive ? 'tab-active' : ''

    return (
      <LiItem onClick={onClick} isActive={isActive} className={className} isAnimated={isAnimated}>
        {label}
      </LiItem>
    )
  }
}

export default IngredientTabs
