import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { breakpoints } from '../MediaQueries'

const Signup = styled.form`

`

const Input = styled.input`
  align-items: stretch;
  box-shadow: 0 0 3px 3px #cfdfde;
  height: 64px;
  border: none;
  border-radius: 5px 0 0 5px;
  padding: 5px 25px 5px 25px;
  font-size: 16pt;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  width: 70%;

  @media (min-width: ${breakpoints.tabletMin}){
    width: 60%;
  }
`

const SubscribeButton = styled.button`
  width: 64px;
  height: 64px;
  border-radius: 0 5px 5px 0;
  background-color: #f3f3f3;
  color: #555555;
  font-size: 16pt;
  display: inline-block;
  vertical-align: middle;

  -webkit-appearance: none;
  top: 0;
  right: 10px;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;

  :hover, :focus{
    cursor: pointer;
    background-color: #e4e4e4;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;
  }

  :active{
  background-color: #dedede;
  border: none;
  outline: none;
  }
`

function EmailForm ({ onValidated, status, message }) {

  const [email, setEmail] = useState('')
  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value)
  }, [])

  const submit = (e) => {
    e.preventDefault()
    if(email.includes("@")) {
      onValidated({EMAIL: email})
    }
  }

  return (
    <Signup>
      {status === "sending" && <div style={{ paddingBottom: '10px' }}>sending...</div>}
      {status === "error" && <div style={{ paddingBottom: '10px' }} dangerouslySetInnerHTML={{__html: message}}/>}
      {status === "success" && <div style={{ paddingBottom: '10px' }}>Subscribed</div>}
      <Input type="email" placeholder="Your email" value={email} onChange={onChangeEmail} />
      <SubscribeButton onClick={submit}>→</SubscribeButton>
    </Signup>
  )
}

function MailChimp(){
  return(
    <MailchimpSubscribe url="https://awkward.us13.list-manage.com/subscribe/post?u=2c830884a7ec38e5d24799727&amp;id=157cd4f386" render={({ subscribe, status, message }) => (
    <EmailForm onValidated={subscribe} status={status} message={message} />
    )} />
  )
}

export default MailChimp 
