import styled, { css } from 'styled-components/macro'

const Button = styled.button`
  color: ${props => props.theme.button.primary.fg};
  background-color: ${props => props.theme.button.primary.bg};
  border: 1px solid ${props => props.theme.button.primary.border};
  text-align: center;
  padding: 13px 30px;
  display: inline-block;
  white-space: nowrap;
  font-size: 20px;
  border-radius: 8px;
  font-weight: 600;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  flex: 1;
  cursor: pointer;
  transition: transform 0.2s;

  p {
    line-height: 25px;
    margin: 0;
    padding: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: #181818;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    transform: translate(0, -2px);
  }

  &:active {
    transform: translate(0, 0);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }

  :disabled {
    cursor: default;
  }

  & + & {
    margin-left: 16px;
  }

  ${props => props.fill && `width: 100%`};

  ${props =>
    props.intent === 'secondary' &&
    css`
      color: ${props => props.theme.button.secondary.fg};
      background-color: ${props => props.theme.button.secondary.bg};
      border: 1px solid ${props => props.theme.button.secondary.border};
    `}
`

Button.defaultProps = {
  intent: 'primary',
  size: 'regular',
  fill: false,
}

export default Button
