
import React, { useState } from 'react'
import { FadeIn, FadeInOut, ShopButton } from 'components/ShopPopupStyling'
import Checkmark from 'components/Checkmark'

const ConditionalShopButton = props => {
  const [clicked, setClicked] = useState(false)

  return (
    <ShopButton
      {...props}
      onClick={e => {
        props.onClick(e)
        setClicked(true)
      }}
    >
      {props.buttonState && <Checkmark />}
      {!clicked && <FadeIn animationEnabled={false}>{props.addToCart ? 'Add To Cart': 'Buy Now'}</FadeIn>}
      {clicked && props.buttonState && <FadeInOut animationEnabled>Added</FadeInOut>}
      {clicked && !props.buttonState && <FadeIn animationEnabled>{props.addToCart ? 'Add To Cart': 'Buy Now'}</FadeIn>}
    </ShopButton>
  )
}

export default ConditionalShopButton
