import React from 'react'
import styled from 'styled-components'
import Heading from 'components/Heading'
import { Link } from 'react-router-dom'
import Button from 'components/Button'
import Paragraph from 'components/Paragraph'
import { breakpoints } from '../MediaQueries'

const TwoColumnCont = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${props => props.mobileFlex || 'row'};

  @media (min-width: ${breakpoints.tabletMin}) {
    width: 900px;
    flex-direction: ${props => props.flexDirection || 'row'};
  }

  @media (min-width: ${breakpoints.tabletMax}) {
    width: 1100px;
    flex-direction: ${props => props.flexDirection || 'row'};
  }
`

const Left = styled.div`
  width: 90%;
  height: auto;
  margin-bottom: 20px;

  @media (min-width: ${breakpoints.tabletMin}) {
    width: 45%;
  }
`

const Title = styled(Heading)`
  font-size: 24px;
  line-height: 38px;

  @media (min-width: ${breakpoints.tabletMin}) {
    font-size: 40px;
    line-height: 56px;
  }
`

const Text = styled(Paragraph)`
  color: rgba(0, 0, 0, 0.45);
`

const Image = styled.img`
  width: 100%;
  height: 400px;
  border-radius: 4px;
  margin-bottom: 80px;
  border: 0;
  object-fit: cover;

  @media (min-width: ${breakpoints.tabletMin}) {
    width: 45%;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const ShowHideButton = styled(Button)`
  display: ${props => props.showButton || 'none'};

  &:hover {
    background-color: #181818;
  }
`

/* begin SplitDiv render */
function TwoColumnDiv(props) {
  return (
    <TwoColumnCont flexDirection={props.flexDirection} mobileFlex={props.mobileFlex}>
      <Left>
        <Title>{props.leftTitle}</Title>
        <Text>{props.leftText}</Text>
        <Link to="/shop">
          <ShowHideButton showButton={props.showButton}>
            <p>Shop All Products</p>
          </ShowHideButton>
        </Link>
      </Left>
      <Image src={props.image} />
    </TwoColumnCont>
  )
}

export default TwoColumnDiv

