import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../MediaQueries'
import bg from 'images/bg.png'
import Footer from 'components/Footer'
import NavbarConditional from 'components/Navbar'

const Layout = styled(({ children, className }) => <div className={className}>{children}</div>)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Header = styled(({ className }) => (
  <div className={className}>
    <NavbarConditional />
    <HeaderContent>
      <HeaderTitle>Privacy Policy of Coffee by Benjamin</HeaderTitle>
      <HeaderDescription>This Application collects some Personal Data from its Users.</HeaderDescription>
    </HeaderContent>
  </div>
))`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 480px;
  width: 100%;
  background-image: url(${bg});
  background-color: #ccdcdc;
  background-position: center;

  @media (min-width: ${breakpoints.tabletMin}) {
    height: 600px;
    padding-top: 40px;
  }
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
  text-align: center;
  padding: 10px;

  @media (min-width: ${breakpoints.tabletMin}) {
    padding: 0px;
  }
`

const HeaderTitle = styled.h1`
  font-size: 40px;
  margin-top: 32px;
  font-weight: 800;

  @media (min-width: ${breakpoints.tabletMin}) {
    font-size: 48px;
  }
`

const HeaderDescription = styled.p`
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 32px;
`

const Container = styled.div`
  width: 100%;
  max-width: 2100px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${breakpoints.tabletMin}) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-width: ${breakpoints.desktopMin}) {
    padding-left: 100px;
    padding-right: 100px;
  }
`

const NarrowContainer = styled(Container)`
  max-width: 960px;
`

const ContentContainer = styled(NarrowContainer)`
  margin-top: 60px;
  margin-bottom: 100px;

  .iubenda_legal_document > h1 {
    display: none;
  }

  .iubenda_legal_document > h1 + p {
    display: none;
  }

  .iubenda_legal_document {
    line-height: 32px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.45);
  }

  .iubenda_legal_document > h2 {
    font-size: 30px;
    line-height: 56px;
    font-weight: 800;
  }

  .iubenda_legal_document > b {
    color: black;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000;
  }
`

class Privacy extends React.Component {
  componentDidMount() {
    const loader = function(d) {
      var s = d.createElement('script'),
        tag = d.getElementsByTagName('script')[0]
      s.src = 'https://cdn.iubenda.com/iubenda.js'
      tag.parentNode.insertBefore(s, tag)
    }
    loader(document)
  }

  render() {
    return (
      <Layout>
        <Header />

        <ContentContainer>
          <a
            href="https://www.iubenda.com/privacy-policy/57346887"
            className="iubenda-white no-brand iubenda-embed iub-no-markup iub-body-embed"
            title="Privacy Policy"
          >
            Privacy Policy
          </a>
        </ContentContainer>

        <Footer displayRuler />
      </Layout>
    )
  }
}
export default Privacy
