import React, { useContext } from 'react'
import ProductHome from './ProductHome'
import { Container } from 'components/Container'
import { CartContext } from 'modules/shop/CartContext'
import styled from 'styled-components/macro'

const ContainerFlex = styled(Container)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const HomeStore = () => {
  const { state, addVariantToCart } = useContext(CartContext)
  let products = state.products.map(product => {
    return <ProductHome addVariantToCart={addVariantToCart} key={product.id.toString()} product={product} />
  })
  return <ContainerFlex>{products}</ContainerFlex>
}

export default HomeStore
