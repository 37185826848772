import React, { Component } from 'react'
import IngredientContent from './IngredientContent'
import TwoColumnDiv from 'components/TwoColumnDiv'
import styled from 'styled-components/macro'
import tray from 'images/tray.jpg'
import coffees from 'images/coffees.jpg'
import thermometer from 'images/thermometer.jpg'
import app from 'images/app.jpg'

const FixedTwoColumnDiv = styled(TwoColumnDiv)`
  > div {
    background-color: blue;
  }
`

class Ingredients extends Component {
  render() {
    return (
      <IngredientContent>
        <div label="Roasting Tray">
          <FixedTwoColumnDiv
            leftTitle="Specifically designed
            roasting tray"
            leftText="We have specifically designed the tray for the roast process. The metal housing is
            of the same material as that of a professional roaster and is perforated all-over to ensure that the air
            supply can evenly roast the beans."
            image={tray}
            showButton={true}
          />
        </div>
        <div label="Green Coffees">
          <FixedTwoColumnDiv
            leftTitle="Three premium green
            coffees"
            leftText="Three types of the highest quality coffee beans complete the package. Unroasted coffee
            beans from the east coast of Africa: from Kenya, Tanzania, and Ethiopia."
            image={coffees}
            showButton={true}
          />
        </div>
        <div label="Thermometer">
          <FixedTwoColumnDiv
            leftTitle="Bluetooth Thermometer"
            leftText="We want to make sure that your oven indicates the right temperature. Thanks to the supplied
            wireless Bluetooth thermometer, we assure you of an optimal roast!"
            image={thermometer}
            showButton={true}
          />
        </div>
        <div label="Mobile App ">
          <FixedTwoColumnDiv
            leftTitle="Coffee by Benjamin
            application "
            leftText="Download the Coffee by Benjamin app (iOS, Android) and connect your Bluetooth thermometer to keep
            track of the temperature, time and statistics of your roast."
            image={app}
            showButton={true}
          />
        </div>
      </IngredientContent>
    )
  }
}

export default Ingredients
