import { useState, useContext, useCallback } from 'react'
import { CartContext } from 'modules/shop/CartContext'

const useAddToCartState = ({ variantId, variantQuantity }) => {
  const [buttonState, setButtonState] = useState(false)
  const { addVariantToCart } = useContext(CartContext)

  const onClickBuyNow = useCallback(() => {
    addVariantToCart(variantId, variantQuantity)
  }, [addVariantToCart, variantId, variantQuantity])

  const onClickAddToCart = useCallback(() => {
    onClickBuyNow()
    setButtonState(true)
    setTimeout(() => {
      setButtonState(false)
    }, 1600)
  }, [onClickBuyNow])

  return { onClickAddToCart, buttonState, onClickBuyNow, addVariantToCart }
}

export default useAddToCartState
