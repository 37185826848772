import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { breakpoints } from '../../MediaQueries'
import Heading from 'components/Heading'
import Paragraph from 'components/Paragraph'
import Footer from 'components/Footer'
import { Container } from 'components/Container'
import NavbarConditional from 'components/Navbar'

const Layout = styled(({ children, className }) => (
  <div className={className}>
    <Header />
    {children}
    <Footer displayRuler />
  </div>
))`
  display: flex;
  flex-direction: column;
  background: white;
  min-height: 100vh;
`

const Header = styled(({ className }) => (
  <div className={className}>
    <NavbarConditional />
  </div>
))`
  @media (min-width: ${breakpoints.tabletMin}) {
    padding-top: 40px;
  }
`

const ContentContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 auto;
  margin-top: 160px;
  color: #fff;
  @media (min-width: ${breakpoints.tabletMin}) {
    margin-top: 0;
    align-items: center;
  }
`

const CenterContainer = styled.div`
  width: 100%;
`

const CenterTitle = styled(Heading)`
  text-align: center;
  color: black;
`

const CenterText = styled(Paragraph)`
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  width: 100%;
  max-width: 500px;
  text-decoration: underline;

  > a {
    color: black;
  }
`

export default function NotFound() {
  return (
    <Layout>
      <ContentContainer>
        <CenterContainer>
          <CenterTitle size="hero" as="h1">
            Page not found
          </CenterTitle>
          <CenterText>
            <Link to="/">Return to Homepage &rarr;</Link>
          </CenterText>
        </CenterContainer>
      </ContentContainer>
    </Layout>
  )
}
