import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled(({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10Z"
        fill="#CCCCCC"
      />
      <path
        className="segment"
        d="M16.364 16.364C16.7545 16.7545 17.3921 16.7574 17.7417 16.3298C18.7755 15.0654 19.4872 13.5627 19.8079 11.9509C20.1937 10.0111 19.9957 8.00043 19.2388 6.17317C18.4819 4.3459 17.2002 2.78412 15.5557 1.6853C14.1893 0.772303 12.6234 0.213005 10.9984 0.0499548C10.4488 -0.00518137 10 0.447715 10 1C10 1.55228 10.4494 1.99356 10.9974 2.06242C12.2262 2.21683 13.4072 2.65513 14.4446 3.34824C15.7602 4.22729 16.7855 5.47672 17.391 6.93853C17.9965 8.40034 18.155 10.0089 17.8463 11.5607C17.6029 12.7843 17.0777 13.9293 16.318 14.9074C15.9792 15.3436 15.9734 15.9734 16.364 16.364Z"
        fill="#181818"
      />
    </svg>
  )
})`
  .segment {
    transform-origin: 50% 50%;
    animation: ${rotate} 0.8s linear infinite;
  }
`

export default Spinner
