import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../MediaQueries'
import CenterText from 'components/CenterText'
import scroll from 'images/scroll.svg'
import Button from 'components/Button'
import Ingredients from 'components/Ingredients'
import Footer from 'components/Footer'
import { Link } from 'react-router-dom'
import Heading from 'components/Heading'
import Paragraph from 'components/Paragraph'
import { Container, FullWidthContainer } from 'components/Container'
import NavbarConditional from 'components/Navbar'
import HomeStore from 'components/cart/HomeStore'
import Promo from 'components/Promo'
import coffeeBoxes  from 'images/coffeeboxes.jpg'
import thermometer from 'images/thermometer.jpg'
import applicationOnDevices from 'images/application-on-devices.png'
import tray from 'images/tray.jpg'
import headerSmall from 'images/website-header-small.jpg'
import AppDownload from 'components/AppDownload'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`

const CtaButton = styled(Button)`
  width: 240px;
  font-size: 15px;
  margin-top: 20px;
  margin-right: 20px;

  &:hover {
    background-color: #181818;
  }
  @media (min-width: ${breakpoints.mobileMin}) {
    font-size: 18px;
    width: unset;
  }
`

const CtaWhiteButton = styled(CtaButton)`
  background-color: white;
  color: black;

  &:hover {
    background-color: white;
  }
`

const ScrollIcon = styled.div`
  width: 23px;
  height: 35px;
  background-image: url(${scroll});
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  left: calc(50% - 13px);
  bottom: 30px;
  opacity: 0;

  @media (min-width: ${breakpoints.tabletMin}) {
    opacity: 1;
  }
`

const HeaderHeading = styled(Heading).attrs({ size: 'hero', as: 'h1' })`
  margin-top: 0;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tabletMin}) {
    max-width: 100%;
  }
`

const HeaderDescription = styled(Paragraph)`
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.85);

  @media (min-width: ${breakpoints.tabletMin}) {
    max-width: 90%;
  }
`

const HeaderContainer = styled(Container)`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  align-items:center;

  @media (min-width: ${breakpoints.tabletMin}) {
    flex-direction: row;
  }
`

const HeaderImage = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (min-width: ${breakpoints.tabletMin}) {
    flex-direction: row;
    width: 50%;
  }
`

const HeaderText = styled.div`
  display: block;
  padding: 25px;

  @media (min-width: ${breakpoints.tabletMin}) {
    padding: 0px;
  }
`

const Header = styled(({ className }) => (
  <div className={className}>
    <NavbarConditional />
    <HeaderContainer>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center"}}>
        <HeaderText>
          <HeaderHeading>Speciality coffee made by you. This is the home roasting kit.</HeaderHeading>
          <HeaderDescription>
            Discover coffee like never before. We provide a complete toolkit for you to start roasting at home.
          </HeaderDescription>
          <Link style={{ color: 'black' }} to="/product/Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ2Njk0ODM1ODE1NzU=">
            <CtaWhiteButton>
              <p>Buy For €99</p>
            </CtaWhiteButton>
          </Link>
          <Link style={{ color: 'white' }} to="/about">
            <CtaButton>
              <p>Watch Video</p>
            </CtaButton>
          </Link>
          <AppDownload />
        </HeaderText>
      </div>
      <HeaderImage src={headerSmall} />
    </HeaderContainer>
    <ScrollIcon />
  </div>
))`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  background-color: #cfdfde;

  @media (min-width: ${breakpoints.tabletMin}) {
    min-height: 95vh;
    padding-top: 40px;
    background-size: 100%;
    background-position: 20% 40%;
    background-repeat: no-repeat;
    background-color: #cfdfde;
  }
`

const BodyWhite = styled(FullWidthContainer)`
  background-color: white;
  padding: 130px 0;
`
const BodyGraySolid = styled.div`
  background-color: #fafafa;
  padding: 0 0;
  @media (min-width: ${breakpoints.tabletMin}) {
    padding: 130px 0;
  }
`

const BodyGradient = styled.div`
  @media (min-width: ${breakpoints.tabletMin}) {
    background: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
  }
`

const BodyGradientMobile = styled.div`
  background: linear-gradient(180deg, #fafafa 0%, #ffffff 100%);
  @media (min-width: ${breakpoints.tabletMin}) {
    background: white;
  }
`

const CenterButton = styled.div`
  display: flex;
  justify-content: center;
  width: 245px;
  margin: 0 auto;

  @media (min-width: ${breakpoints.tabletMin}) {
    margin-top: 20px;
    width: 260px;
  }
`

function useMediaQuery(mq) {
  const mql = window.matchMedia(mq)
  const [matches, setMatches] = React.useState(mql.matches)

  React.useEffect(() => {
    const listener = e => {
      setMatches(e.matches)
    }
    mql.addListener(listener)
    return () => {
      mql.removeListener(listener)
    }
  }, [mq, mql])

  return matches
}

const SectionTitle = styled.h1`
  font-size: ${props => props.small ? '24' : '32' }px;
  line-height: ${props => props.small ? '38' : '46'}px;
  text-align: ${props => props.center ? 'center' : 'left' };
  color: #000000;
  margin: 0 0 32px 0;
  font-weight: 800;
  

  @media(min-width: ${breakpoints.tabletMin}) {
    font-size: ${props => props.small ? '40' : '48' }px;
    line-height: ${props => props.small ? '56' : '68'}px;
    text-align: ${props => props.center || props.onlyBigCenter ? 'center' : 'left' };
  }
`
const SectionHeader = styled.div`
  width: 100%;
  max-width: 632px;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 96px;

  @media (min-width: ${breakpoints.tabletMin}) {
    margin-top: 0;
    padding: 160px 0 0 0;
  }
`

const SectionContainer = styled.div`
  width: 100%;
  max-width: 1308px;
  margin: auto;

  padding-left: 25px;
  padding-right: 25px;
  padding-top: 40px;

  @media (min-width: ${breakpoints.tabletMin}) {
    margin-top: 0;
    padding-top: 160px;
    padding-bottom: 0;
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 312px;
  margin: auto;
  border-radius: 4px;
  margin-bottom: 16px;
  object-fit: contain;
  mix-blend-mode: multiply;

  @media (min-width: ${breakpoints.tabletMin}) {
    transform: ${props => props.tray ? 'rotate(30deg)' : 'none'};
    height: auto;
    width: 90%;
    margin-top: 0;
    margin-bottom: 96px;
  }
`

const TrayImage = styled.img`
  display: block;
  width: 100%;
  height: 312px;
  margin: auto;
  border-radius: 4px;
  object-fit: contain;
  mix-blend-mode: multiply;

  @media (min-width: ${breakpoints.tabletMin}) {
    transform: rotate(30deg);
    height: auto;
    width: 90%;
    margin-top: 0;
    margin-bottom: 0;

    position: absolute;
    right: -300px;
    bottom: -350px;
  }
`

const TraySectionContainer = styled(SectionContainer)`
  position: relative;

  @media (min-width: ${breakpoints.tabletMin}) {
    padding-bottom: 450px;
  }

  & + ${SectionContainer} {

  }
`

const HomeParagraph = styled(Paragraph)`
  @media (min-width: ${breakpoints.tabletMin}) {
    text-align: ${props => props.center ? 'center' : 'left' };
    margin-bottom: 150px;
  }
`

const Col = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.tabletMin}) {
  max-width: ${props => props.n * 68 + ((props.n - 1) * 40)}px;
  margin-left: ${props => props.shift * 68 + props.shift * 40}px;
}
`

Col.defaultProps = {
  shift: 0
}


function Home() {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobileMax})`)
  return (
    <Layout>
      <Header />
        <BodyGraySolid>
          <CenterText
            centerTitle="Our Coffees"
            centerText="This is our selection of high quality raw coffee beans suitable for home roasting."
          ></CenterText>
          <HomeStore />
          <Link to="/shop">
            <CenterButton>
              <Button>
                <p>Shop All Products</p>
              </Button>
            </CenterButton>
          </Link>
        </BodyGraySolid>

      {!isMobile ? (
        <BodyWhite>
          <CenterText
            centerTitle="The Starter Kit"
            centerText="Get the freshest coffee experience into your kitchen. Our Starter Kit includes everything to turn you into a homeroaster. No prior knowledge required, just a love for coffee."
          ></CenterText>
          <Ingredients />
        </BodyWhite>
      ) : (
      <>
        <BodyGradientMobile>
          <SectionHeader>
            <SectionTitle center>The Starter Kit</SectionTitle>
            <Paragraph style={{textAlign: 'center'}}>
              Get the freshest coffee experience into your kitchen. Our Starter Kit includes everything to turn you into a homeroaster. No prior knowledge required, just a love for coffee.
            </Paragraph>
          </SectionHeader>

          <BodyGradient style={{ overflow: "hidden" }}>
            <TraySectionContainer>
              <Col n={5} shift={1}>
                <SectionTitle small style={{ textAlign: 'left' }}>Specifically designed roasting tray</SectionTitle>
                <Paragraph>
                  We have specifically designed the tray for the roast process. The metal housing is of the same material as that of a professional roaster and is perforated all-over to ensure that the air supply can evenly roast the beans.
                </Paragraph>
              </Col>
              <TrayImage src={tray} />
            </TraySectionContainer>
          </BodyGradient>
          <SectionContainer>
            <Col n={5} style={{ margin: 'auto' }}>
              <SectionTitle small onlyBigCenter>Three premium<br/>green coffees</SectionTitle>
              <HomeParagraph center>
                Three types of high-quality coffee beans (32 OZ / 900g in total weight) complete the package. Raw coffee beans from the east coast of Africa: from Kenya, Tanzania,<br/>and Ethiopia.
              </HomeParagraph>
            </Col>
            <Image src={coffeeBoxes} />
          </SectionContainer>
          <BodyGradient style={{ display: 'flex' }}>
            <SectionContainer>
              <Col n={5} shift={1}>
                <SectionTitle small style={{ textAlign: 'left' }}>Bluetooth thermometer</SectionTitle>
                <HomeParagraph>We want to make sure that your oven indicates the right temperature. Thanks to the supplied wireless Bluetooth thermometer, we assure you of an optimal roast!</HomeParagraph>
              </Col>
              <Image src={thermometer} style={{ mixBlendMode: 'multiply'}} />
            </SectionContainer>
          </BodyGradient>
          <SectionContainer>
            <Col n={5} style={{ margin: 'auto' }}>
              <SectionTitle small onlyBigCenter>Coffee by Benjamin app</SectionTitle>
              <HomeParagraph center>Download the Coffee by Benjamin app and connect your Bluetooth thermometer to keep track of the temperature, time and statistics of your roast.</HomeParagraph>
            </Col>
            <Image src={applicationOnDevices}/>
          </SectionContainer>
        </BodyGradientMobile>
      </>
      )}
      <Promo />
      <Footer />
    </Layout>
  )
}

export default Home
