import styled, { css } from 'styled-components'
import { ReactComponent as LogoIcon } from 'images/logo.svg'

const Logo = styled(LogoIcon)`
  z-index: 999;
  width: 152px;
  fill: #131313;

  ${props =>
    props.theme.darkMode &&
    css`
      fill: #fff;
    `}
`

export default Logo
