import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../../MediaQueries'
import { Container } from 'components/Container'
import Heading from 'components/Heading'
import Paragraph from 'components/Paragraph'
import Button from 'components/Button'
import Footer from 'components/Footer'
import DarkMode from 'components/DarkMode'
import NavbarConditional from 'components/Navbar'

const Layout = styled(({ children, className }) => (
  <div className={className}>
    <Header />
    {children}
    <Footer displayRuler />
  </div>
))`
  display: flex;
  flex-direction: column;
  background: black;
  min-height: 100vh;
`

const Header = styled(({ className }) => (
  <div className={className}>
    <NavbarConditional />
  </div>
))`
  @media (min-width: ${breakpoints.tabletMin}) {
    padding-top: 40px;
  }
`

const ContentContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 auto;
  margin-top: 50px;
  color: #fff;
  @media (min-width: ${breakpoints.tabletMin}) {
    margin-top: 0;
    align-items: center;
  }
`

const CenterContainer = styled.div`
  width: 100%;
`

const CenterTextCont = styled.div`
  padding-top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  background-position: center;
  margin-bottom: 30px;
`

const CenterTitle = styled(Heading)`
  text-align: center;
`

const CenterText = styled(Paragraph)`
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  width: 100%;
  max-width: 750px;

  > a {
    color: white;
    text-decoration: underline;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin: 0 auto;
  flex: 1 0 auto;
  justify-content: center;
  text-align: center;
`
const CenterButton = styled(Button)`
  margin-top: 25px;

  :hover {
    background-color: white;
  }
`

export default function Support() {
  return (
    <DarkMode>
      <Layout>
        <ContentContainer>
          <CenterContainer>
            <CenterTextCont>
              <CenterTitle size="hero" as="h1">
                Coffee By Benjamin Press Kit
              </CenterTitle>
              <CenterText style={{ color: 'white' }}>
                Thank you for your interest in Coffee by Benjamin! Here you can find photos and copy that will help you
                share our story. For more information about working with us or for press inquiries, please contact{' '}
                <a href="mailto:support@coffeebybenjamin.com">support@coffeebybenjamin.com &rarr;</a>
              </CenterText>
              <ButtonContainer>
                <a href="https://www.dropbox.com/sh/lr6fvv126gf76em/AACvHvBIWv6gYuLaJzOywyq4a?dl=0">
                  <CenterButton>Go to Press Kit</CenterButton>
                </a>
              </ButtonContainer>
            </CenterTextCont>
          </CenterContainer>
        </ContentContainer>
      </Layout>
    </DarkMode>
  )
}
