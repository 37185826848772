import React from 'react'
import styled from 'styled-components'
import Heading from 'components/Heading'
import Paragraph from 'components/Paragraph'
import { breakpoints } from '../MediaQueries'
import { FullWidthContainer } from 'components/Container'

const FullWidthContainerMargin = styled(FullWidthContainer)`
  width: 90%;
  @media (min-width: ${breakpoints.tabletMin}) {
    width: 90%;
    padding-top: 0;
  }
`

const Title = styled(Heading).attrs({ size: 'hero', as: 'h2' })`
  text-align: center;
  margin: 0 auto 30px auto;
  max-width: 700px;
`
const Text = styled(Paragraph)`
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin: 0 auto;
  max-width: 900px;

  a {
    color: black;
    text-decoration: underline;
  }
`

function CenterText(props) {
  return (
    <FullWidthContainerMargin>
      <Title>{props.centerTitle}</Title>
      <Text>
        {props.centerText}
        <a href="mailto:info@benjamin.nl">{props.link}</a>
      </Text>
    </FullWidthContainerMargin>
  )
}

export default CenterText

