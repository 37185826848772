import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Container } from 'components/Container'
import PropTypes from 'prop-types'
import IngredientTabs from './IngredientTabs'

const TabsCont = styled(Container)``

const ContentContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 40px 0 0 0;
`

const List = styled.ol`
  display: flex;
  justify-content: space-around;
  padding-left: 0;
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  opacity: ${props => (props.isActive ? 1 : 0)};
  z-index: ${props => (props.isActive ? 2 : 1)};
  transition: opacity 0.5s;
`

class IngredientContent extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  componentDidMount() {
    this.timer = window.setInterval(() => {
      const currentActiveTab = this.state.activeTab
      const currentIndex = this.props.children.findIndex(child => {
        return child.props.label === currentActiveTab
      })

      const nextIndex = currentIndex + 1
      this.setState({
        activeTab: this.props.children[nextIndex % 4].props.label,
      })
    }, 5000)
  }

  componentWillUnmount() {
    if (this.timer) window.clearInterval(this.timer)
  }

  constructor(props) {
    super(props)
    this.state = {
      activeTab: this.props.children[0].props.label,
      isAnimated: true,
    }
  }

  onClickTabItem = tab => {
    window.clearInterval(this.timer)
    this.setState({ activeTab: tab, isAnimated: false })
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this

    return (
      <TabsCont>
        <List>
          {React.Children.map(children, child => {
            const { label } = child.props
            return (
              <IngredientTabs
                key={label}
                label={label}
                onClick={onClickTabItem}
                isActive={child.props.label === activeTab}
                isAnimated={this.state.isAnimated}
              />
            )
          })}
        </List>
        <ContentContainer>
          {React.Children.map(children, child => {
            return <Content isActive={child.props.label === activeTab}>{child}</Content>
          })}
        </ContentContainer>
      </TabsCont>
    )
  }
}

export default IngredientContent
