import React from 'react'
import { breakpoints } from '../../MediaQueries'
import styled from 'styled-components'
import Footer from 'components/Footer'
import Heading from 'components/Heading'
import Paragraph from 'components/Paragraph'
import Navbar from 'components/Navbar'
import { Container } from 'components/Container'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Header = styled(({ className }) => (
  <div className={className}>
    <Navbar />
  </div>
))`
  @media (min-width: ${breakpoints.tabletMin}) {
    padding-top: 40px;
  }
`

const CartElement = styled(Container)`
  position: relative;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`

const CartElementCenter = styled(CartElement)`
  justify-content: center;
  align-items: center;
  display: flex;
`

const Download = () => {
  if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
    window.location.href = 'https://play.google.com/store/apps/details?id=co.awkward.benjamin';
  }
  if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
      window.location.href = 'https://apps.apple.com/nl/app/coffee-by-benjamin/id1459846234';
  }
  
  return (
    <Layout>
      <Header />
        <CartElementCenter>
          <Heading as="h2" size="large" textAlign="center">
            Redirecting you to the App Store...
          </Heading>
          <Paragraph style={{ textAlign: 'center', marginTop: 0 }}>The app is a guide that helps you roast your own coffee in your oven. It's available for both Android and iOS.</Paragraph>
        </CartElementCenter>
      <Footer displayRuler />
    </Layout>
  )
}

export default Download
