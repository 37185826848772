export const lightTheme = {
  darkMode: false,
  button: {
    primary: {
      fg: '#fff',
      bg: '#181818',
      border: 'rgba(0, 0, 0, 0.05)',
    },
    secondary: {
      fg: 'rgba(0, 0, 0, 0.85)',
      bg: '#fff',
      border: 'rgba(0, 0, 0, 0.05)',
    },
  },
  header: {
    link: 'rgba(0, 0, 0, 0.85)',
    linkHover: 'rgba(0, 0, 0, 0.45)',
  },
  footer: {
    fg: '#181818;',
    bg: '#fff',
    ruler: '#fafafa',
  },
}

export const darkTheme = {
  ...lightTheme,
  darkMode: true,
  button: {
    primary: {
      fg: 'rgba(0, 0, 0, 0.85)',
      bg: '#fff',
      border: 'rgba(0, 0, 0, 0.05)',
    },
    secondary: {
      fg: 'rgba(0, 0, 0, 0.85)',
      bg: '#fff',
      border: 'rgba(0, 0, 0, 0.05)',
    },
  },
  header: {
    link: '#fff',
    linkHover: 'rgba(255, 255, 255, 0.45)',
  },
  footer: {
    fg: '#fff',
    bg: '#000',
    ruler: '#262626',
  },
}
