import React, { Component } from 'react'
import { ShopifyContext } from './ShopifyContext'

export const CartContext = React.createContext()

export default class CartProvider extends Component {
  static contextType = ShopifyContext

  constructor() {
    super()

    this.state = {
      checkout: { lineItems: [] },
      products: [],
      shop: {},
    }

    this.addVariantToCart = this.addVariantToCart.bind(this)
    this.addDiscountCode = this.addDiscountCode.bind(this)
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this)
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this)
    this.deleteQuantity = this.deleteQuantity.bind(this)
    this.decrementQuantity = this.decrementQuantity.bind(this)
    this.incrementQuantity = this.incrementQuantity.bind(this)
    this.handleQuantityChange = this.handleQuantityChange.bind(this)
  }

  componentDidMount() {
    const client = this.context

    const createCart = () => {
      client.checkout.create().then(res => {
        // if cartID hasn't been set
        this.setState(
          {
            checkout: res,
          },
          function() {
            const cartID = this.state.checkout.id
            localStorage.setItem('cartID', cartID)
          },
        )
      })
    }

    if (localStorage.getItem('cartID') !== null) {
      // If cartID has been set
      const currentID = localStorage.getItem('cartID')
      
      client.checkout.fetch(currentID).then(res => {
        // Clear checkout when order has been made
        if(!res || res.order) {
          localStorage.clear()
          createCart()
        } else {
          this.setState({
            checkout: res,
          })
        }
      })
    } else {
      createCart()
    }

    client.product.fetchAll().then(res => {
      this.setState({
        products: res,
      })
    })

    client.shop.fetchInfo().then(res => {
      this.setState({
        shop: res,
      })
    })
  }

  getTotalCartItems = () => {
    return this.state.checkout.lineItems.reduce((total, item) => {
      return item.quantity + total
    }, 0)
  }

  addVariantToCart(variantId, quantity) {
    const client = this.context

    this.setState({
      isCartOpen: true,
    })

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]
    const checkoutId = this.state.checkout.id

    return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
      this.setState({
        checkout: res,
      })
    })
  }

  addDiscountCode(discountCode) {
    const client = this.context

    const checkoutId = this.state.checkout.id

    return client.checkout.addDiscount(checkoutId, discountCode).then(res => {
      this.setState({
        checkout: res,
      })
    })
  }

  updateQuantityInCart(lineItemId, quantity) {
    const client = this.context
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10) }]

    return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
      this.setState({
        checkout: res,
      })
    })
  }

  handleOptionChange(event) {
    const client = this.context
    const target = event.target
    let selectedOptions = this.state.selectedOptions
    selectedOptions[target.name] = target.value

    const selectedVariant = client.product.helpers.variantForOptions(this.props.product, selectedOptions)

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image,
    })
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value,
    })
  }

  removeLineItemInCart(lineItemId) {
    const client = this.context
    const checkoutId = this.state.checkout.id

    return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
      this.setState({
        checkout: res,
      })
    })
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity)
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity)
  }

  deleteQuantity(lineItemId) {
    const updatedQuantity = 0
    this.props.updateQuantityInCart(lineItemId, updatedQuantity)
  }

  render() {
    return (
      <CartContext.Provider
        value={{
          addVariantToCart: this.addVariantToCart,
          addDiscountCode: this.addDiscountCode,
          state: this.state,
          updateQuantityInCart: this.updateQuantityInCart,
          removeLineItemInCart: this.removeLineItemInCart,
          getTotalCartItems: this.getTotalCartItems,
        }}
      >
        {this.props.children}
      </CartContext.Provider>
    )
  }
}
