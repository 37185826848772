import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from '../MediaQueries'

import { Link } from 'react-router-dom'

import { ReactComponent as Fb } from '../images/icons/black/fb.svg'
import { ReactComponent as Twitter } from '../images/icons/black/twit.svg'
import { ReactComponent as Insta } from '../images/icons/black/insta.svg'
import { Container } from './Container'

const FooterContainer = styled(Container)`
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: ${breakpoints.tabletMin}) {
    margin-top: 0;
  }
`

const Ruler = styled.span`
  left: 0px;
  height: 2px;
  margin-top: -30px;
  position: absolute;
  width: 100%;
  background-color: ${props => props.theme.footer.ruler};
  display: block;
`

const Copyright = styled.div`
  color: ${props => props.theme.footer.fg};
  width: 100%;

  @media (min-width: ${breakpoints.tabletMin}) {
    display: inline-block;
    width: auto;
  }
`

function getYear(){
  const date = new Date();
  var year = date.getFullYear();
  return year;
}

const Links = styled.div`
  text-align: left;
  margin-left: 0px;
  font-weight: medium;
  width: 100%;

  @media (min-width: ${breakpoints.tabletMin}) {
    display: inline-block;
    font-weight: medium;
    text-align: left;
    margin-left: 40px;
    width: auto;
  }
`

const Linker = styled.p`
  display: block;
  padding: 0;
  color: ${props => props.theme.footer.fg};
  transition: opacity 0.3s ease 0s;
  font-weight: 500;

  @media (min-width: ${breakpoints.tabletMin}) {
    display: inline;
    padding: 20px;
  }

  &:hover {
    opacity: 0.6;
  }
`

const SocialIcons = styled.div`
  width: 100%;
  margin-top: 20px;

  @media (min-width: ${breakpoints.tabletMin}) {
    width: auto;
    margin-top: 0px;
  }
`
const Icon = styled.div`
  display: inline-block;
  padding-right: 19px;
  transition: opacity 0.3s ease 0s;

  &:hover {
    opacity: 0.4;
  }
`

const IconInsta = styled(Insta)`
  fill: ${props => props.theme.footer.fg};
`

const IconFb = styled(Fb)`
  fill: ${props => props.theme.footer.fg};
`

const IconTwitter = styled(Twitter)`
  fill: ${props => props.theme.footer.fg};
`

function Footer(props) {
  return (
    <FooterContainer>
      <Ruler />

      <div>
        <Copyright>© {getYear()} - Coffee by Benjamin</Copyright>

        <Links>
          <Link to="/faq">
            <Linker>FAQ</Linker>
          </Link>
          <Link to="/privacy">
            <Linker>Privacy Policy</Linker>
          </Link>
          <Link to="/support">
            <Linker>Support</Linker>
          </Link>
          <Link to="/press-kit">
            <Linker>Press Kit</Linker>
          </Link>
        </Links>
      </div>

      <SocialIcons>
        <Icon>
          <a href="https://www.instagram.com/coffeebybenjamin/">
            <IconInsta />
          </a>
        </Icon>
        <Icon>
          <a href="https://www.facebook.com/coffeebybenjamin/">
            <IconFb />
          </a>
        </Icon>
        <Icon>
          <a href="https://twitter.com/coffeebyben">
            <IconTwitter />
          </a>
        </Icon>
      </SocialIcons>
    </FooterContainer>
  )
}

export default Footer
